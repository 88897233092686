import React from 'react';
import { Box, Grid, Typography, List, ListItem, Checkbox, FormControlLabel, Button } from "@mui/material";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { getAnswersByTopic, createWellnessAttributeAnswer } from '../../utilityFunctions/WellnessUtil';
import Colors from "../../components/ui/Colors";
import { loaderAnimation } from '../../Api';
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function ConsumerPlanDetail(props) {
    const { loginUser } = props;
    const history = useHistory();
    const wellnessTopic = props.location.state?.topic;

    const [loading, setLoading] = React.useState(false);
    const [checkedChoices, setCheckedChoices] = React.useState({});

    React.useEffect(() => {
        const loadInitialValues = async () => {
            if (!wellnessTopic) return;

            setLoading(true);
            const response = await getAnswersByTopic({ topicId: wellnessTopic.id }, loginUser.jwtToken);
            setLoading(false);

            if (!response) {
                swal({ title: "Error", text: "Failed to get wellness topics", icon: "error", button: "OK" });
                return;
            }

            const initialCheckedChoices = response.reduce((acc, answer) => {
                acc[`${answer.wellnessAttributeId}-${answer.wellnessChoiceId}`] = true;
                return acc;
            }, {});

            console.log(initialCheckedChoices);
            setCheckedChoices(initialCheckedChoices);
        };

        loadInitialValues();
    }, [wellnessTopic, loginUser.jwtToken]);

    const toggleChoice = (attributeId, choiceId) => {
        const choiceKey = `${attributeId}-${choiceId}`;
        setCheckedChoices(prevState => ({
            ...prevState,
            [choiceKey]: !prevState[choiceKey],
        }));
    };

    const submitAnswers = async () => {
        setLoading(true);
        const selectedChoiceIds = Object.keys(checkedChoices)
            .filter(choiceKey => checkedChoices[choiceKey])
            .map(choiceKey => choiceKey.split('-')[1]);

        console.log(selectedChoiceIds);

        const response = await createWellnessAttributeAnswer({ choicesIds: selectedChoiceIds }, loginUser.jwtToken);
        setLoading(false);

        if (!response) {
            swal({ title: "Error", text: "Failed to submit the form", icon: "error", button: "OK" });
            return;
        }

        swal({ title: "Success", text: "Form submitted successfully", icon: "success", button: "OK" })
            .then(() => history.push("/retirement_plan/topics"));
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: {xs: "90%", sm: "70%", md: "50%"}, height: "90%", textAlign: "center", marginTop: { xs: "1.3rem", sm: 0 }}}>
                {loading ? loaderAnimation :
                    <Grid container direction="row" spacing={1} sx={{height: { xs: '80vh', sm: '80vh', md: '80vh'}, overflowY: 'auto', marginTop: {sx: "1rem", sm: "1rem", md: "3rem"}}}>
                        <Grid item xs={12}>
                            <List sx={{ width: "100%", marginTop: { xs: "3rem", sm: "2rem", md: "2rem" } }}>
                                {wellnessTopic.wellnessAttributes.map(attribute => (
                                    <ListItem key={attribute.id} disablePadding sx={{ marginBottom: "2.5rem" }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", textAlign: "start" }}>
                                                <Typography variant="body1" sx={{ fontWeight: "bold", color: "black" }}>{attribute.name}</Typography>
                                            </Grid>
                                            {attribute.wellnessChoices?.map(choice => (
                                                console.log(`${attribute.id}-${choice.id}`),
                                                <React.Fragment key={`attribute-${attribute.id}-choice-${choice.id}`}>
                                                    <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="secondary"
                                                                    checked={!!checkedChoices[`${attribute.id}-${choice.id}`]}
                                                                    onChange={() => toggleChoice(attribute.id, choice.id)}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={10} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                                        <Typography variant="body2" sx={{ color: "dimGray" }}>{choice.question}</Typography>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                        </Grid>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={submitAnswers} variant="contained" sx={{ backgroundColor: Colors.primary, marginTop: "2rem" }}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    );
}