import React, { useState, useCallback, useEffect } from "react";
import { useHistory} from "react-router-dom";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
import ContentForm from "../../layout/ContentForm"
import StepProgress from "../../components/ui/StepProgress";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Colors from "../../components/ui/Colors";
import TextField from '@mui/material/TextField';
import { loaderAnimation } from "../../Api";
import { t } from "i18next";

const steps = [

  {
    key: "password",
    name: t("ResetPassword.newPwd"),
    value: "",
  },
  {
    key: "confirmPassword",
    name: t("ResetPassword.confirmPwd"),
    value: "",
  },
];

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const PASSWORDMESSAGE = t("ResetPassword.pwdHelper");

export default function ResetPassword(props) {
  const { wupoServerUri } = props;
  const query = new URLSearchParams(props.location.search);
  const token = query.get('token');
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({
    resetToken: token,
    password: "",
    confirmPassword: "",
  });
  const [stepNumber, setStepNumber] = useState(0);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const [strengthMessage, setStrengthMessage] = useState("")
  const [strengthLevel, setStrengthLevel] = useState(0);

  useEffect(() => {
    setInputText("");
  }, [stepNumber]);

  const onChange = useCallback((e) => {
    setInputText(e.target.value);
    checkPassword(e.target.value);
    setUserInfo({
        ...userInfo,
        [steps[stepNumber].key]: e.target.value,
    });
  }, [userInfo, stepNumber]);

  const resetPassword = () => {
    const resetPasswordData = {
        password: userInfo.password,
        confirmPassword: userInfo.confirmPassword,
        resetToken: userInfo.resetToken
    };
    
    fetch(`${wupoServerUri.devnet}/api/auth/reset`, {
        method: "POST",
        body: JSON.stringify(resetPasswordData),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
          if(!response.ok) return Promise.reject(response);
          return response.json();
      })
      // catch error response and extract the error message
      .catch(async response => {
          const error = await response.json().then(json => json)
          console.log(error);
          return Promise.reject(error)
      })
      .then(data => {
          setLoading(false);
          setStepNumber(0);
          setInputText("");
          
          swal({
              title: t("ResetPassword.resetPwdSuccess"),
              // text: `\n ${data.message}`,
              icon: "success",
              button: "ok",
          }).then(() =>{
            history.push("/");
          });
      })
      .catch(error => {
          setLoading(false);
          setStepNumber(0);
          setInputText("");
          swal({
              title: t("ResetPassword.resetPwdError"),
              // text: `\n ${error?.subErrors ? error.subErrors[0].message : error.message}`,
              icon: "error",
              button: "ok",
          });
      });
  };

  const handleNextClick = () => {
    if (!inputText) return;
    if(steps[stepNumber].key === 'password'){
      if(strengthLevel < 4){
        setColor("red");
        setStrengthMessage(PASSWORDMESSAGE);
        return;
      }
    }
    

    if (stepNumber < steps.length - 1) {
      setStepNumber(stepNumber + 1);
    } else {
      if(userInfo.password !== userInfo.confirmPassword){
        setStrengthMessage(t("ResetPassword.passwordsDontMatch"));
        setColor("red");
        return;
      }else {
        setStrengthMessage("");
      }
      setLoading(true);
      resetPassword();
    }
  };
  const checkPassword = (password) => {
    //Regular Expressions.
    const regex = new Array();
    regex.push("[A-Z]"); //Uppercase Alphabet.
    regex.push("[a-z]"); //Lowercase Alphabet.
    regex.push("[0-9]"); //Digit.
    regex.push("[$@$!%*#?&]"); //Special Character.

    let level = 0;

    //Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
          level++;
        }
    }

    //Validate for length of Password.
    if (level > 2 && password.length > 8) {
      level++;
    }

    if (password.length > 10) {
      level++;
    }
    
    switch (level) {
        
        case 1:
          setStrengthMessage(PASSWORDMESSAGE);
          setColor("red");
        break;
        case 2:
          setStrengthMessage(PASSWORDMESSAGE);
          setColor("darkgrey");
        break;
        case 3:
        case 4:
          setStrengthMessage(PASSWORDMESSAGE);
          setColor("darkorange");
        break;
        case 5:
          setStrengthMessage("ok");
          setColor("green");
        break;
        case 6:
          setStrengthMessage("ok");
          setColor("darkgreen");
        break;
        case 0:
        default:
          setStrengthMessage("");
          setColor("");
          setStrengthLevel(0);
        
    }
    setStrengthLevel(level);
  };
  return (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
      }}
    >
      <ContentForm>
        {loading ? (
          loaderAnimation
        ) : (
          <>
            <StepProgress stepNumber={stepNumber} steps={steps} />
            <PageLabel>{steps[stepNumber].name}</PageLabel>
            {/* <Input value={inputText} type={"password"} onChangeInfo={onChange} /> */}
            <div style={{maxWidth: "250px", width: "100%"}}>
                <TextField
                  required
                  variant="standard"
                  id="password"
                  type="text"
                  name="password"
                  label={t("ResetPassword.password")}
                  fullWidth
                  value={inputText}
                  onChange={onChange}
                />
              
              { color && strengthMessage ?
                <em style={{color: color, fontSize: "0.8rem"}}>{strengthMessage}</em>
                :
                <></>
                }
            </div>
            <AppButton primary onClick={handleNextClick} width={"60%"}>
              {stepNumber === steps.length - 1 ? "Ok" : "Ok"}
            </AppButton>
          </>
        )}
      </ContentForm>
    </div>
  );
}
