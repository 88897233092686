import React, { useState, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
// import Content from "../../layout/Content";
import ContentForm from "../../layout/ContentForm"
import Input from "../../components/Input";
import StepProgress from "../../components/ui/StepProgress";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Colors from "../../components/ui/Colors";
import TextField from '@mui/material/TextField';
import { loaderAnimation } from "../../Api";
import { useTranslation } from 'react-i18next';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function PasswordRecovery(props) {
  const { wupoServerUri } = props;

  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState({
    email: ""
  });
  
  const [inputText, setInputText] = useState("");
  const [inputType, setInputType] = useState("email");
  const [length, setLength] = useState(100);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInputText("");
    setInputType("email");
    setLength(100);
  }, []);

  useEffect(() => {
    swal({
      title: t("PasswordRecovery.recoverPwd"),
      text: t("PasswordRecovery.recoveryPwdSteps"),
      icon: "success",
      button: "Ok",
    });
  }, []);

  const onChange = (e) => {
    setInputText(e.target.value);
    setUserInfo({
      ...userInfo,
      email: e.target.value,
    });
  };

  const requestPasswordRecovery = () => {
    const user = {
      email: userInfo.email
    };

    fetch(`${wupoServerUri.devnet}/api/auth/recover-password`, {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
        if(!response.ok) return Promise.reject(response);
        return response.json();
    })
    // catch error response and extract the error message
    .catch(async response => {
        const error = await response.json().then(json => json)
        console.log(error);
        return Promise.reject(error)
    })
    .then(data => {
        setLoading(false);
        setInputText("");
        
        swal({
            title: t("PasswordRecovery.emailSent"),
            // text: `Te acabamos de enviar un correo para que cambies tu contraseña.
            // Recuerda revisar tus correos no deseados `,
            icon: "success",
            button: "Ok",
        });
    })
    .catch(error => {
        setLoading(false);
        setInputText("");
        swal({
            title: "ERROR",
            text: t("PasswordRecovery.invalidEmail"),
            icon: "error",
            button: "Ok",
        });
    });
  };

  const handleSubmitClick = () => {
    if (!inputText) return;
    setLoading(true);
    requestPasswordRecovery();
  };

  return (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
      }}
    >
      <ContentForm>
        {loading ? (
          loaderAnimation
        ) : (
          <>
            <PageLabel>{t("PasswordRecovery.email")}</PageLabel>
            {/* <Input value={inputText} type={inputType} length={length} onChangeInfo={onChange} required /> */}
            <div style={{maxWidth: "300px", width: "100%"}}>
                <TextField
                  variant="standard"
                  required
                  id="email"
                  type="email"
                  name="email"
                  label={t("PasswordRecovery.email")}
                  autoComplete="given-email"
                  fullWidth
                  value={inputText}
                  onChange={onChange}
                />
                </div>
            <AppButton primary onClick={handleSubmitClick} width={"60%"}>
                Ok
            </AppButton>
          </>
        )}
      </ContentForm>
    </div>
  );
}
