import React from 'react';
import {Box, Grid, TextField, Typography, Autocomplete, Button} from "@mui/material";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {getAllWellnessTopic, createWellnessChoice} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import NumberFormat from "react-number-format";
import { loaderAnimation } from '../../../Api';
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const choiceRequest = {
    question: "",
    score: "",
    attributeId: "",
};

export default function CreateWellnessChoice(props) {
    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [wellnessTopics, setWellnessTopics] = React.useState(null);

    React.useEffect(() => {
        initialValues();
    }, []);

    const initialValues = async () => {
        setLoading(true);
        const response = await getAllWellnessTopic(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get wellness topics",
                icon: "error",
                button: "OK",
            });
            return;
        }

        setWellnessTopics(response);
    };

    const [wellnessTopic, setWellnessTopic] = React.useState(null);

    const topicChange = (e, value) => {
        if(!value){
            return;
        }

        setWellnessTopic(value);
        
    };

    const [wellnessQuestion, setWellnessQuestion] = React.useState("");

    const questionChange = (e, value) => {
        if(!value){
            return;
        }

        setWellnessQuestion(value.id);
        choiceRequest.attributeId = value.id;
    };

    const [wellnessChoice, setWellnessChoice] = React.useState("");

    const changeWellnessChoice = (e) => {
        choiceRequest.question = e.target.value;
        setWellnessChoice(e.target.value);
    };

    const [wellnessChoiceScore, setWellnessChoiceScore] = React.useState("");

    const wellnessScoreChange = (values) => {
        choiceRequest.score = values.floatValue;
        setWellnessChoiceScore(values.floatValue);
    };

    const submitWellnessChoice = async () => {
        setLoading(true);
        const response = await createWellnessChoice(choiceRequest, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to create wellness choice",
                icon: "error",
                button: "OK",
            });
            return;
        }

        swal({
            title: "Success",
            text: "Wellness choice created successfully",
            icon: "success",
            button: "OK",
        }).then(() => {
            history.push({
                pathname: "/handle_profile",
            })
        });
        return;
    }

    
    
    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? loaderAnimation :
                <Grid container direction="row" spacing={2}>
                {wellnessTopics &&
                    <>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                            <Autocomplete
                                sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                options={wellnessTopics}
                                getOptionLabel={(option) => option.name}
                                clearOnEscape
                                autoComplete = {true}
                                noOptionsText = {"topic not found"}
                                fullWidth
                                onChange={topicChange}
                                renderInput={(params) => (
                                    <TextField  {...params} label="Topic" variant="standard" />
                                )}
                            />
                        </Grid>
                        {wellnessTopic && wellnessTopic.wellnessAttributes &&
                            <>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Autocomplete
                                        sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                        options={wellnessTopic.wellnessAttributes}
                                        getOptionLabel={(option) => option.name}
                                        clearOnEscape
                                        autoComplete = {true}
                                        noOptionsText = {"Question not found"}
                                        fullWidth
                                        onChange={questionChange}
                                        renderInput={(params) => (
                                            <TextField  {...params} label="Questions" variant="standard" />
                                        )}
                                    />
                                </Grid>
                                {wellnessQuestion &&
                                    <>
                                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1rem"}}>
                                            <TextField
                                                sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                                variant="outlined"
                                                fullWidth={true}
                                                multiline
                                                rows={4}
                                                label={<Typography variant="body2">Choice</Typography>}
                                                value={wellnessChoice}
                                                onChange={(e)=> changeWellnessChoice(e)}
                                                autoComplete="off"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                            <NumberFormat
                                                variant="standard"
                                                style={{width: "40%", height: "50%"}}
                                                customInput={TextField}
                                                // prefix={'$ '}
                                                // suffix={' years'}
                                                type="text"
                                                label={<Typography variant="body2">Score</Typography>}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                autoComplete="off"
                                                value={wellnessChoiceScore}
                                                onValueChange={(values) => {
                                                    wellnessScoreChange(values);
                                                }}
                                            />
                                        </Grid>
                                        {(wellnessChoiceScore || wellnessChoiceScore===0) &&
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Button onClick={submitWellnessChoice} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                                    Submit
                                                </Button>
                                            </Grid>
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }
                </Grid>
            }
            </Box>
        </Box>
    )
}
