import {wupoServerUri} from '../Api';

const createWellnessTopic = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/createWellnessTopic`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const getWellnessTopic = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/wellnessTopic`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const getAllWellnessTopic = async(jwtToken) => {
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/allWellnessTopic`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const createWellnessAttribute = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/createWellnessAttribute`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const createWellnessChoice = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/createWellnessChoice`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const getAnswersByTopic = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getWellnessAnswersByTopic`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const createWellnessAttributeAnswer = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/createWellnessAttributeAnswers`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const persistPersonalInfo = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/createPersonalInfo`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const calculateUserTopicScores = async(jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/calculateUserTopicScores`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const modifyWellnessTopic = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/modifyWellnessTopic`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const modifyWellnessAttribute = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/modifyWellnessAttribute`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const modifyWellnessChoice = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/modifyWellnessChoice`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

export {createWellnessTopic, getWellnessTopic, getAllWellnessTopic, createWellnessAttribute, 
    createWellnessChoice, getAnswersByTopic, createWellnessAttributeAnswer, persistPersonalInfo,
    calculateUserTopicScores, modifyWellnessTopic, modifyWellnessAttribute, modifyWellnessChoice};