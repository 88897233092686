import {wupoServerUri} from '../Api';

const getAllUsers = async(jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/allUsersInfo`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const getAllClientUsers = async(jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/allClientUsersInfo`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const getAllAdvisorUsers = async(jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/allAdvisorUsersInfo`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}



export{getAllAdvisorUsers, getAllClientUsers, getAllUsers};