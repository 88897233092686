import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import NavBar from "./layout/NavBar";
import Container from "./layout/Container";


import {
  HanldeProfile,
  CreateWellnessTopic,
  CreateWellnessAttribute,
  CreateWellnessChoice,
  ChangeWellnessTopic,
  ChangeWellnessAttribute,
  ChangeWellnessChoice,
  ClientsReport,
  AdvisorsReport,
} from "./pages/admin";

import {
  Activity,
  AMLForm,
  ContactLocation,
  IDInformation,
  PersonalInformation
} from "./pages/AML-Information";

import { 
  ConsumerPlan,
  ConsumerPlanDetail,
  ConsumerPlanCalculation,
 } from "./retirement/consumer";


import history from "./HistoryExport"

const AuthedRoutes = (mainProps) => {

// console.log(mainProps.navBarEnabled) //DEBUG
  return (
    <Router history={history}>
    {mainProps.navBarEnabled ? <NavBar {...mainProps} /> : "" }
      <Container>
        <Switch>
        
          {/* <Route exact path="/" component={Home} /> */}

          <Route
            exact
            strict
            path="/handle_profile"
            render={(props) =>
              React.createElement(HanldeProfile, { ...props, ...mainProps })}
          />

          <Route 
            exact 
            path="/onboarding/personalinformation" 
            render={(props) => 
            React.createElement(PersonalInformation, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/account/generalinfo" 
            render={(props) => 
            React.createElement(AMLForm, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/account/idinfo" 
            render={(props) => 
            React.createElement(IDInformation, { ...props, ...mainProps })} />
          <Route 
            exact 
            path="/account/contactinfo" 
            render={(props) => 
            React.createElement(ContactLocation, { ...props, ...mainProps })} />
          <Route 
            exact 
            path="/account/activityinfo" 
            render={(props) => 
            React.createElement(Activity, { ...props, ...mainProps })} />  
          <Route 
            exact 
            path="/admin/wellness_structure/new_topic" 
            render={(props) => 
            React.createElement(CreateWellnessTopic, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/wellness_structure/new_attribute" 
            render={(props) => 
            React.createElement(CreateWellnessAttribute, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/wellness_structure/new_answer" 
            render={(props) => 
            React.createElement(CreateWellnessChoice, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/wellness_structure/change_topics" 
            render={(props) => 
            React.createElement(ChangeWellnessTopic, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/wellness_structure/change_attributes" 
            render={(props) => 
            React.createElement(ChangeWellnessAttribute, { ...props, ...mainProps })} 
          />
           <Route 
            exact 
            path="/admin/wellness_structure/change_answers" 
            render={(props) => 
            React.createElement(ChangeWellnessChoice, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/reports_consumers" 
            render={(props) => 
            React.createElement(ClientsReport, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/admin/reports_advisors" 
            render={(props) => 
            React.createElement(AdvisorsReport, { ...props, ...mainProps })} 
          />


          {/* Consumers */}
          <Route 
            exact 
            path="/retirement_plan/topics" 
            render={(props) => 
            React.createElement(ConsumerPlan, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/retirement_plan/detail" 
            render={(props) => 
            React.createElement(ConsumerPlanDetail, { ...props, ...mainProps })} 
          />
          <Route 
            exact 
            path="/retirement_plan/dashboard" 
            render={(props) => 
            React.createElement(ConsumerPlanCalculation, { ...props, ...mainProps })} 
          />          
         
        </Switch>
      </Container>
    </Router>
  );
}

export default AuthedRoutes;
