import React, { useState } from "react";
import AuthedRoutes from "../AuthedRoutes";
import Routes from "../Routes";
import {
  wupoServerUri,
  retailOperation,

} from "../Api";


let isAuth=false;

export default function Auth() {
  const [user, setUser] = useState();
  const [ledgerAccounts, setLedgerAccounts] = useState([]);
  const [loginUser, setLoginUser] = useState({});
  const [registerUser, setRegisterUser] = useState();
  const [visitorAccounts, setVisitorAccounts] = useState();
  const [visitorLoans, setVisitorsLoans] = useState();
  const [creditRiskStatus, setCreditRiskStatus] = useState();
  const [navBarEnabled, setNavBarEnabled] = useState(true);
  const [wupoAvailableAssets, setWupoAvailableAssets] = useState();
  const [loanCostsSettings, setLoanCostsSettings] = useState();
  const [loanConditionsSettings, setLoanConditionsSettings] = useState();
  const [refinancingCostsSettings, setRefinancingCostsSettings] = useState();
  const [refinancingConditionsSettings, setRefinancingConditionsSettings] = useState();
  const [displayExplanations, setDisplayExplanations] = useState();
  const [dealsDefinitions, setDealsDefinitions] = useState();
  const [dealsList, setDealsList] = useState();

  const updateNavBarEnabled = (enabled) =>{
    console.log(enabled);
    setNavBarEnabled(enabled)
    return navBarEnabled;
  };


  const updatedUserData = (userData) => {
    console.log(userData);
    if(userData!==null){
      isAuth=true;
    }else{
      isAuth=false;
    }
    
    // console.log(userData); // DEBUG PRINTING
    const userUpdate = ledgerAccounts.find(
      (el) => userData && el.accountId === userData.accountId
    );
    if (userUpdate) {
      // localStorage.setItem(
      //   "loggedInUser",
      //   JSON.stringify({ ...userData, ...userUpdate })
      // );
      setUser({ ...userData, ...userUpdate });
      // console.log("user in updatedUserData: " + JSON.stringify(user)) // DEBUG PRINTING
    } else {
      // setUser(userData);
      // setLoginUser({...userData, loginUser});
      setLoginUser(prevLoginUser => ({
        ...prevLoginUser,
        ...userData
      }));
     
      if(retailOperation.retailLoginEnable){
        if(userData !== null){
          // console.log("jwt Token: " + JSON.stringify(userData.jwtToken)) // DEBUG PRINTING
          const retailerJwt={
            jwtToken: userData.jwtToken
          };
          
        }
      }
    }
  };

  const updateRegisterUser = (userData) => {
    setRegisterUser(userData)
  };

  
  const updateUser = (asset) => {
    // console.log(loginUser);
    // console.log(asset);
    isAuth=true;


    const existUser =
            user &&
            ledgerAccounts.find((el) => el.account === user.account && el.asset===asset);
    if (existUser) {
      setUser(existUser);
      // console.log(existUser)
      // console.log("user in updateLedger if: " + JSON.stringify(user)) // DEBUG PRINTING
    }

  }

  return isAuth ? ( //loginUser
        <AuthedRoutes
          user={user}
          loginUser={loginUser}
          wupoServerUri={wupoServerUri}
          userdata={updatedUserData}
          updateUser={updateUser}
          visitorAccounts={visitorAccounts}
          visitorLoans={visitorLoans}
          creditRiskStatus={creditRiskStatus}
          updateNavBarEnabled={updateNavBarEnabled}
          navBarEnabled={navBarEnabled}
          wupoAvailableAssets={wupoAvailableAssets}
          loanCostsSettings={loanCostsSettings}
          loanConditionsSettings={loanConditionsSettings}
          refinancingCostsSettings={refinancingCostsSettings}
          refinancingConditionsSettings={refinancingConditionsSettings}
          displayExplanations={displayExplanations}
          dealsDefinitions={dealsDefinitions}
          dealsList={dealsList}
        />
      )
      :
      (
        <Routes 
          userdata={updatedUserData} 
          updateRegisterUser={updateRegisterUser}
          wupoServerUri={wupoServerUri}
          registerUser={registerUser}
          updateNavBarEnabled={updateNavBarEnabled}
          navBarEnabled={navBarEnabled}
          visitorLoans={visitorLoans}
          visitorAccounts={visitorAccounts}
          loanCostsSettings={loanCostsSettings}
          loanConditionsSettings={loanConditionsSettings}
          displayExplanations={displayExplanations}
          
        />
      );
}
