import React from "react"
import { Router, Route, Switch, Redirect } from "react-router-dom"
// import { Router, Route, Switch, Redirect } from "react-router-dom"
import Home from "./pages/Home"
import {
  Register,
  QRRegister,
  Login,
  PasswordRecovery,
  ResetPassword
} from "./pages/auth"

import Container from "./layout/Container"

import history from "./HistoryExport"

const Routes = (mainProps) => {
  return (
    <Router history={history}>
      <Container>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/register" render={(props) => React.createElement(Register, { ...props, ...mainProps })} />
          <Route exact path="/qrregister" component={QRRegister} />
          <Route exact path="/login" render={(props) => React.createElement(Login, { ...props, ...mainProps })} />
          <Route exact path="/forgot-password" render={(props) => React.createElement(PasswordRecovery, { ...props, ...mainProps })} />
          <Route exact path="/reset" render={(props) => React.createElement(ResetPassword, { ...props, ...mainProps })} />
          <Redirect to="/" />
        </Switch>
      </Container>
    </Router>
  )
}

export default Routes;