const privacyConsent = 
`
This text is My Plan Keeper AI data processing consent. It explains how and for what purposes we will use your personal data. These consent exist because your personal information belongs to you, and third parties (including us) can only use it if you consent. We ask for your consent by pressing the "I accept" button to use your personal data as explained below.

There are two types of uses for your data. The first is to support the activities necessary to provide you with our services, and the second is to offer you products and services that meet your needs and that we believe will make your life easier and more enjoyable. These uses are divided into the purpose of the use (purpose) and the way we process them for that purpose (processing). We describe them below:

Purposes:
i) Identity verification: We need to know who you are to link you and carry out your transactions.
ii) Execution of activities that support the products and services you have with us.
iii) Risk assessment: Including money laundering, fraud, and operational risks. Also financial risk.
iv) We have service providers to be more efficient. If necessary, these third parties may process your data under our responsibility (this is known as transmission). We have providers for cloud storage and processing, risk assessment, biometrics, and other fundamental activities for providing our services.
v) Profiling and commercial offers: It is very important for us to understand you and know your needs to offer you products and services that you really need and that help you enjoy life. If you are not happy with our commercial offers, you can ask us to stop them at any time (revoke your consent).

We also need to explain how we will achieve the above. We process your data in the following ways: capturing, verifying, analyzing, monitoring, ordering, updating, combining, associating, and splitting them. All these verbs are the specific types of processing and are included so you know exactly what we do.

Additionally, to provide you with a better service, we may consult legitimate third-party databases.

Since you own your data, you have a set of rights listed below: i) Know, update, and rectify your information, ii) Request proof of consent, iii) Be informed about the use of your data, iv) File complaints with regulators, v) Revoke consent and/or request the deletion of your data.

To ask us to delete, stop using your data, or enforce any of your rights, write to us at the following email: contacto@wupealo.com

Remember that you are giving this consent to My Plan Keeper AI, electronic address https://www.myplankeeper.ai/.
`;

export {privacyConsent}

export default privacyConsent;