import React from 'react';
import {Box, Grid, TextField, Typography, Button} from "@mui/material";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {createWellnessTopic} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const topicRequest = {
    name: "",
};

export default function CreateWellnessTopic(props) {
    
    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [topic, setTopic] = React.useState("");

    const changeTopic = (e) => {
        setTopic(e.target.value);
        topicRequest.name = e.target.value;
    }

    const submitTopic = async () => {
        setLoading(true);
        const response = await createWellnessTopic(topicRequest, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to create wellness topic",
                icon: "error",
                button: "OK",
            });
            return;
        }

        swal({
            title: "Success",
            text: "Wellness topic created successfully",
            icon: "success",
            button: "OK",
        }).then(() => {
            history.push({
                pathname: "/handle_profile",
            })
        });
        return;

    }
    
    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? loaderAnimation:
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "1rem"}}>
                        <TextField
                            variant="outlined"
                            fullWidth={true}
                            multiline
                            rows={2}
                            label={<Typography variant="body2">Topic</Typography>}
                            value={topic}
                            onChange={(e)=> changeTopic(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={submitTopic} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            }
            </Box>
        </Box>
    )
}
