// import React, {useEffect} from "react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Colors from "../components/ui/Colors";

import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import{fetchDevicesUsers} from "./../utilityFunctions/FetchUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { loaderAnimation } from "../Api";


const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const autocompleteInfo = {
  deviceFingerprint: "",
  autocompleteUsers: false,
  usersAutocompleteArray: "",
};

const fpPromise = FingerprintJS.load();
// const fpPromise = FingerprintJS.load({token: 'nDQ31LvAqr3zFsWM8omJ'});

export default function Home(props) {
  const{updateDisplayExplanations} = props

  let history = useHistory();

  const[loading, setLoading] = React.useState(false);

  useEffect(() => {
    deviceFingerPrint();
  },[])

  const deviceFingerPrint = async () =>{
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    autocompleteInfo.deviceFingerprint = result.visitorId;
    console.log(autocompleteInfo.deviceFingerprint); //DEBUG PRINTING

    const FPVerification = {
      visitorsFingerprint: result.visitorId
    };

    setLoading(true);
    const deviceUsers = await fetchDevicesUsers(FPVerification);
      
      if(deviceUsers !== "Not found"){
        autocompleteInfo.autocompleteUsers = true;
        autocompleteInfo.usersAutocompleteArray = deviceUsers;
        setLoading(false);
        history.push({ 
          pathname: "/login", 
          state:{
            autocompleteInfo: autocompleteInfo
          }
        });
        return;
      }
      else{
          setLoading(false);
          history.push({ 
            pathname: "/register", 
          });
          return;  
      }
  }

  return (
    <div
      style={{
        display: "flex",
        // flexGrow: "1",
        width: "100%",
        height: "80%",
        justifyContent: "center",
        // alignContent:"center",
      }}
    >
      {/* {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : */}
      {loading ? loaderAnimation :
        <div
          style={{
            // width: "100vw",
            maxWidth: "670px",
            // maxWidth: "60%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
        
        </div>
      }
    </div>
  );
}
