import React from 'react';
import {Grid, Box, Typography, List, ListItem} from '@mui/material';
import Colors from "../../../components/ui/Colors";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import swal from 'sweetalert';
import {getAllAdvisorUsers} from '../../../utilityFunctions/AdminUtil';
import { loaderAnimation } from '../../../Api';
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
};

export default function AdvisorsReport(props) {

    const {loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [advisorUsers, setAdvisorUsers] = React.useState(null);


    React.useEffect(() => {
        const loadInitialValues = async () => {
            await initialValues();
        }
        loadInitialValues();
    }, []);

    const initialValues = async () => {
        setLoading(true);
        const response = await getAllAdvisorUsers(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Info",
                text: "Advisors not found",
                icon: "info",
                button: "OK",
            }).then(() => {
                history.push("/handle_profile");
                return;
            });
            
        }

        else if(response.length === 0){
            swal({
                title: "Info",
                text: "Advisors not found",
                icon: "info",
                button: "OK",
            }).then(() => {
                history.push("/handle_profile");
                return;
            });
        }

        setAdvisorUsers(response);
        return response;
    }



    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
             <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
                {loading ? loaderAnimation : 
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container sx={{marginTop: "1.5rem"}}>
                                <Grid  item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Name</Typography>
                                </Grid>
                                <Grid  item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Email</Typography>
                                </Grid>
                                <Grid  item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Phone</Typography>
                                </Grid>
                                <Grid  item xs={0} sm={2} md={2} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>City</Typography>
                                </Grid>
                                <Grid  item xs={0} sm={2} md={2} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Onboarding</Typography>
                                </Grid>
                                <Grid  item xs={0} sm={1} md={1} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Logins</Typography>
                                </Grid>
                                <Grid  item xs={0} sm={1} md={1} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Status</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {advisorUsers && 
                            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                                {advisorUsers.map((client, index) => {
                                    return(
                                        <ListItem key={client.id} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                                    <Typography variant="body2">{`${client.firstName} ${client.lastName}`}</Typography>
                                                </Grid>
                                                <Grid item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                                    <Typography variant="body2">{client.email}</Typography>
                                                </Grid>
                                                <Grid item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                                    <Typography variant="body2">{client.phone}</Typography>
                                                </Grid>
                                                <Grid  item xs={0} sm={2} md={2} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center"}}>
                                                    <Typography variant="body2">{client.city}</Typography>
                                                </Grid>
                                                <Grid  item xs={0} sm={2} md={2} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center"}}>
                                                    <Typography variant="body2">{new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(client.createdOn))}</Typography>
                                                </Grid>
                                                <Grid  item xs={0} sm={1} md={1} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center"}}>
                                                    <Typography variant="body2">{client.loginCounter}</Typography>
                                                </Grid>
                                                <Grid  item xs={0} sm={1} md={1} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center"}}>
                                                    <Typography variant="body2">{!!client.accountNonLocked ? "Active" : "Blocked"}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        }
                    </Grid>
                }
            </Box>
        </Box>
    )
}
