import React from 'react';
import {Box, Grid, TextField, Typography, Autocomplete, Button, List, ListItem, 
    ListItemButton} from "@mui/material";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {getAllWellnessTopic, createWellnessChoice} from '../../utilityFunctions/WellnessUtil';
import Colors from "../../components/ui/Colors";
import { loaderAnimation } from '../../Api';
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function ConsumerPlan(props) {

    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [wellnessTopics, setWellnessTopics] = React.useState(null);

    React.useEffect(() => {
        renderTopics();
    }, []);

    const initialValues = async () => {
        setLoading(true);
        const response = await getAllWellnessTopic(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get wellness topics",
                icon: "error",
                button: "OK",
            });
            return;
        }

        setWellnessTopics(response);
        return response;
    };

    const [topicsList, setTopicsList] = React.useState("");

    const renderTopics = async () => {

        const topics = await initialValues();

        if(!topics){
            return;
        }

        setTopicsList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {topics.map((topic) => {
                    return (
                        <ListItem key={generateRandomKey()} disablePadding sx={{marginBottom: "2rem"}}>
                            <ListItemButton key={generateRandomKey()} onClick={() => goToTopic(topic)}>
                                <Grid key={generateRandomKey()}  container spacing={1}>
                                    <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="h6">{topic.name}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        )

    };

    const goToTopic = (topic) => {
        console.log(topic);
        history.push({
            pathname: "/retirement_plan/detail",
            state: {topic: topic}
        });
    }

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                    overflowY: "scroll"
                }}
            >
                {loading ? loaderAnimation:
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            {topicsList}
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    )
}
