import React, { useState, useEffect } from "react";
import { useHistory, Link} from "react-router-dom";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
import StepProgress from "../../components/ui/StepProgress";
import Colors from "../../components/ui/Colors";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Box, Grid, Button, TextField, Autocomplete, Typography} from '@mui/material';
import {getUxLanguage, createWebauthnCredentials, 
  verifyWebauthnCredentials, getWebauthnCredentials, authLogin} from "../../utilityFunctions/AuthUtil";
import { fetchDevicesUsers } from "../../../src/utilityFunctions/FetchUtil";
import { webauthnPKRpId } from "../../Api";
import {loaderAnimation} from "../../Api";
import { useTranslation } from 'react-i18next';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const userNewDevice = {
  username: "",
  password: "",
  totpToken: "",
  deviceConfirmation: "CONFIRMATION_PENDING",
  deviceFingerprint: "",
  totpEnabled: false
};

const fpPromise = FingerprintJS.load();
// const fpPromise = FingerprintJS.load({token: 'nDQ31LvAqr3zFsWM8omJ'});

let profileCompletedCondition = false;


 const loginData = {
        accountId: "",
        ilpBearerToken: "",
        totpToken: "",
        jwtToken: "",
        profileCompleted: "",
        roles: "",
 };

let autocompleteUsers = false; 

let usersAutocompleteArray = null;

const getWebauthnCredentialsRequest ={
  username: "",
};

export default function Login(props) {

  const { userdata, updateNavBarEnabled, navBarEnabled} = props;
  
  const history = useHistory();

  const { t, i18n } = useTranslation();

  const steps = [
    {
      key: "username",
      name: t("Register.email"),
      value: "",
    },
    {
      key: "password",
      name: t("Register.password"),
      value: "",
    }
  ]; 

  const getUserCredentials = async () => {

    console.log("Getting webauthn credentials");    

    setLoading(true);
    const response = await getWebauthnCredentials(getWebauthnCredentialsRequest, loginData.jwtToken);
    setLoading(false);

    if(!response){
      
      handleNextClick();
      return;
    }

    const getWebAuthnRequest = {};
    getWebAuthnRequest.webauthnCredentials = response;
    console.log(getWebAuthnRequest);
    getWebAuthn(getWebAuthnRequest);

    return;
  };

  const getWebAuthn = async(userfpCredentials) => {

    console.log(userfpCredentials);

    if(!userfpCredentials || !userfpCredentials.webauthnCredentials){
      console.log("No webauthn credentials in getwebauthn");
      handleNextClick();
      return;
    }

    const isBiometricAvailable = await checkWebAuthnSupport();

    if(!isBiometricAvailable){
      console.log("Biometric not available");
      handleNextClick();
      return;
    };

    const webAuthnCredentials = userfpCredentials.webauthnCredentials;

    const options = {
      publicKey: {
        // Challenge to prevent replay attacks
        challenge: new Uint8Array(32),
        // Allow all associated credential IDs for the user
        allowCredentials: webAuthnCredentials.map((credentials) => ({
          type: 'public-key',
          id: base64urlToUint8Array(credentials.credentialId),
          transports: ['internal'] // Transports the authenticator supports
        })),
      },
    };

    try{
      console.log(options);
      setLoading(true);
      const assertionResponse = await navigator.credentials.get(options);
      console.log("User has an authenticator registered:", assertionResponse);
      

      // Extract authenticator data
      const authenticatorDataArrayBuffer = assertionResponse.response.authenticatorData;
      // Convert the authenticator data to base64 string
      const authenticatorDataBase64 = arrayBufferToBase64Auth(authenticatorDataArrayBuffer);


      // Extract the signature
      const signatureArrayBuffer = assertionResponse.response.signature;
      // Convert the signature to base64 string
      const signatureBase64 = arrayBufferToBase64Auth(signatureArrayBuffer);


      //Extract clientDataJSON and SHA256 as the signature seems to be over authenticatorData + SHA256(clientDataJSON)

      const clientDataJSON = assertionResponse.response.clientDataJSON;
      // Convert the signature to base64 string
      const clientDataJSONBase64 = arrayBufferToBase64Auth(clientDataJSON);

      const webathnVerRequest = {
        username: userfpCredentials.user,
        credentialId: assertionResponse.id,
        authenticatorData: authenticatorDataBase64,
        clientDataJSON: clientDataJSONBase64,
        signature: signatureBase64
      };

      console.log(webathnVerRequest);

      const response = await verifyWebauthnCredentials(webathnVerRequest, loginData.jwtToken);
      setLoading(false);

      if(!response){
        console.log("Error verifying webauthn credentials");
        return;
      };

      console.log(response);

      handleWebAuthnResponse(response);


    }catch(error){
      
      setLoading(false);
      console.log(error);

      handleNextClick();
    }

  };

  const handleWebAuthnResponse = async(data) => {
    if (data.accessToken && data.verified === true) {
      console.log(navBarEnabled);
      loginData.accountId = data.username;
      loginData.ilpBearerToken = data.ilpBearerToken;
      loginData.totpToken = data.totpToken;
      loginData.jwtToken = data.accessToken;
      loginData.profileCompleted = data.profileCompleted;
      loginData.roles = data.roles;


      profileCompletedCondition = data.profileCompleted;
      
      userdata({
        accountId: data.username,
        ilpBearerToken: data.ilpBearerToken,
        totpToken: data.totpToken,
        jwtToken: data.accessToken,
        profileCompleted: data.profileCompleted,
        roles: data.roles
      });

      const profileName = await determineProfile(data)

      if(data.profileCompleted){
        
        await updateNavBarEnabled(true);

        history.push("/handle_profile");

      }else{

        const loginParams ={
          username: userNewDevice.username,
          password: userNewDevice.password
        };

        history.push({
          pathname: "/onboarding/personalinformation",
          state: {
            loginParams: loginParams
          }
        });
      }
      
    } 
    else {
      setLoading(false);
      swal({
        title: t("Login.incorrectCredentials"),
        // text: t("Login.incorrectCredentials"),
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    } 

  }

  const sha256 = async (message) => {
    // Encode the message as UTF-8
    const msgBuffer = new TextEncoder().encode(message);
    // Calculate the hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    // Convert the hash buffer to a Uint8Array
    return new Uint8Array(hashBuffer);
  };

  const newWebAuthnCredentials = async (userCredentials) => {

    const isBiometricAvailable = await checkWebAuthnSupport();

    if(!isBiometricAvailable){
      console.log("Biometric not available");
      return;
    };

    const hashedUsername = await sha256(userCredentials.user);

    const createWebAuthnOptions = {
      publicKey:{
        rp:{id: webauthnPKRpId , name: "Wupo"},
        user:{
          id: hashedUsername,
          name: userCredentials.user,
          displayName: userCredentials.user,
        },
        challenge: generateRandomChallenge(32),
        pubKeyCredParams: [
          {type: "public-key", alg: -7},
          { type: "public-key", alg: -257 } // -257 represents RS256
        ],
        authenticatorSelection: {
          authenticatorAttachment: "platform",
          userVerification: "required" // Requires user verification during credential creation
        }
      },
      allowCredentials: [
        {
          id: "credentialsIdentifierOne", // Identifier for the platform authenticator
          type: "public-key",
          transports: ["internal"] // Specify "internal" transport for platform authenticators
        },
        // Add other credentials as needed
      ]
    };

    console.log(createWebAuthnOptions);
    
    try{
      setLoading(true);
      const webAuthnResponse = await navigator.credentials.create(createWebAuthnOptions);

      if(!webAuthnResponse){
        setLoading(false);
        console.log("No response from webauthn");
        
        //If the user cancel the registration or the device + browser cannot handle the registration the user
        //will continue with the traditional password registration
        // handleNextClick();
        return;
      };

      // console.log(webAuthnResponse);

      // Extract public key
      const publicKeyArrayBuffer = webAuthnResponse.response.getPublicKey();
      // Convert the public key to base64 string
      const publicKeyBase64 = arrayBufferToBase64Auth(publicKeyArrayBuffer);


      // Extract the authenticator data
      const authenticatorDataArrayBuffer = webAuthnResponse.response.getAuthenticatorData();
      // Convert the authenticator data to base64 string
      const authenticatorDataBase64 = arrayBufferToBase64Auth(authenticatorDataArrayBuffer);

      

      //Challenge validation
      const clientDataJSON = new TextDecoder().decode(webAuthnResponse.response.clientDataJSON);
      const clientData = JSON.parse(clientDataJSON);
      const receivedChallengeBytes = base64urlToUint8Array(clientData.challenge);


      if(!isEqualUint8Arrays(receivedChallengeBytes, createWebAuthnOptions.publicKey.challenge)){
        console.log("Challenge validation failed");
        return;
      }

      const credentialsRequest = {};
      credentialsRequest.username = userCredentials.user;
      credentialsRequest.credentialId = webAuthnResponse.id;
      credentialsRequest.publicKey = publicKeyBase64;
      credentialsRequest.authenticatorData = authenticatorDataBase64;

      setLoading(true);
      const response = await createWebauthnCredentials(credentialsRequest, loginData.jwtToken);
      setLoading(false);

      if(!response){
        swal({
          title: t("Register.biometricError"),
          icon: "error",
          button: "Ok",
        }).then(() => {
          return;
        });
      }

      swal({
        title: t("Register.biometricSuccessfull"),
        icon: "success",
        button: "Ok",
      }).then(() => {
        return;
      });

      console.log(response);
    }catch(error){
      setLoading(false);
      console.log(error);
      // handleNextClick();
      return;
    }

  };

// Function to check WebAuthn and biometric support
const checkWebAuthnSupport = async () => {
  if (!window.PublicKeyCredential) {
    console.log("WebAuthn not supported");
    return null;
  }

  try {
    const isBiometricAvailable = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    if (!isBiometricAvailable) {
      console.log("WebAuthn biometric capacity not available");
      return null;
    }

    return true;

  } catch (error) {
    console.error("Error checking WebAuthn support:", error);
    return null;
  }
};

  const isEqualUint8Arrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
  }

  const base64urlToUint8Array = (base64urlString) => {
    const base64String = base64urlString.replace(/-/g, '+').replace(/_/g, '/');
    const rawData = atob(base64String);
    const buffer = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      buffer[i] = rawData.charCodeAt(i);
    }
    return buffer;
  };

  // Function to convert ArrayBuffer to base64
  const arrayBufferToBase64Auth = (arrayBuffer) => {
    const binary = new Uint8Array(arrayBuffer);
    const binaryString = String.fromCharCode.apply(null, binary);
    return btoa(binaryString);
  }

  const generateRandomChallenge = (length) => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return array;
}

  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
    token: "",
  });


  const [stepNumber, setStepNumber] = useState(0);
  const [inputText, setInputText] = useState("");
  const [inputType, setInputType] = useState("text");
  const [length, setLength] = useState(0);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    
    navBarEnabler();
    
    userNewDevice.totpEnabled = false;
    autocompleteUsers = false;
    userNewDevice.deviceConfirmation = "CONFIRMATION_PENDING";

    const loadInitialValues = async () => {
      /**visitor device Fingerprint identifier */
      await fetchUxLang();
      await deviceFingerPrint();
    };
    loadInitialValues();
    
  }, []);

  useEffect(() => {
    setInputText("");
    if(steps[stepNumber].key === "password") {
      setInputType("password")
    } else if(steps[stepNumber].key === "token") {
      setInputType("number")
      setLength(6)
    } else {
      setInputType("text")
    }
  }, [stepNumber]);

  const [usersAssignedToDevice, setUsersAssignedToDevice] = useState(
    {
    options: [{user: "Sin usuario", pwd: ""}],
    getOptionLabel: (option) => option.user,
    }
  );
  
  const deviceFingerPrint = async () =>{
    
    setLoading(true);
    if(props.location.state && props.location.state.autocompleteInfo){
      userNewDevice.deviceFingerprint = props.location.state.autocompleteInfo.deviceFingerprint;
      autocompleteUsers = props.location.state.autocompleteInfo.autocompleteUsers;
      usersAutocompleteArray = props.location.state.autocompleteInfo.usersAutocompleteArray;
      console.log(userNewDevice.deviceFingerprint); // DEBUG PRINTING
      setLoading(false);
      setUsersAssignedToDevice(
        {
          options: usersAutocompleteArray,
          getOptionLabel: (option) => option.user,
        }
      )
      
    }
    else{
      const fp = await fpPromise
      const result = await fp.get()

      // This is the visitor identifier:
      const visitorId = result.visitorId
      userNewDevice.deviceFingerprint = visitorId;
      console.log(userNewDevice.deviceFingerprint) // DEBUG PRINTING

      const FPVerification = {
        visitorsFingerprint: visitorId
      };
      const deviceUsers = await fetchDevicesUsers(FPVerification);
        
      if(deviceUsers !== "Not found"){
        autocompleteUsers = true;
        usersAutocompleteArray = deviceUsers;
        setLoading(false);
        setUsersAssignedToDevice(
          {
            options: deviceUsers,
            getOptionLabel: (option) => option.user,
          }
        )
        
        // console.log(usersAssignedToDevice);
      }
      else{
        setLoading(false);
      }

    }
  };

  const navBarEnabler = async () => {
    await updateNavBarEnabled(false);
  };

  const fetchUxLang = async() => {
    const langResponse = await getUxLanguage();

    if(!langResponse){
      return;
    }

    i18n.changeLanguage(langResponse.language);
  };

  const onChange = (e) => {

    let input = e.target.value;

    if(steps[stepNumber].key === "username" && input){
      console.log(input)
      input = input.toLowerCase();
      console.log(input)
    }

    setInputText(input);
    setUserInfo({
      ...userInfo,
      [steps[stepNumber].key]: input,
    });

    getWebauthnCredentialsRequest.username = input;
    
  };

  const onChangeAutocomplete = (event, value) => {
    console.log(value);
    if(!value){
      return;
    }

    //turning off the autocomplete so that a password is requested to the user
    autocompleteUsers = false;

    setInputText(value.user);
    setUserInfo({
      ...userInfo,
      [steps[stepNumber].key]: value.user,
    });

    getWebauthnCredentialsRequest.username = value.user;

    getUserCredentials();
  };

  const handleNextClick = () => {
    // console.log(inputText)
    if (!inputText) return;
    if (stepNumber < steps.length - 1) {
      /* Autocomplete checkpoint*/
      if(steps[stepNumber].key === "username" && autocompleteUsers === true){
        console.log("Autocomplete checkpoint");
        const autoUserFiltered = usersAutocompleteArray.find((autoUser) => autoUser.user === inputText);
        if(!autoUserFiltered){
          autocompleteUsers = false;
          setStepNumber(stepNumber + 1);
          return;
        }
        // console.log(autoUserFiltered); //LOANS DEBUG PRINTING
        userNewDevice.password = autoUserFiltered.password;
        // console.log(userNewDevice.password); //LOANS DEBUG PRINTING
        setLoading(true);
        login();
      } else{
          setStepNumber(stepNumber + 1);
          return;
      }

    } else {
        setLoading(true);
        login();
    }
  };

  const login = async () => {
   
    const user = {
      username: userInfo.username,
      password: userInfo.password,
      totpToken: userInfo.token.substring(0, length),
    };
    userNewDevice.username = user.username; 
    userNewDevice.totpToken = user.totpToken;

    if(autocompleteUsers === false){
      userNewDevice.password = user.password;
    }

    const response = await authLogin(userNewDevice);

    if(!response){
      setLoading(false);
      setStepNumber(0);
      setLength(0);
      swal({
        title: t("Login.incorrectCredentials"),
        icon: "error",
        button: "Ok",
      });
      return;
    }

    console.log(response.status);

    const data = await response.json();

    //Login successfull
    if (data.accessToken) {

      
    //Enabling the webauthn credentials and wait until finished due to an error or client close swal after success
    const usernameObject ={};
    usernameObject.user = data.username;
    await newWebAuthnCredentials(usernameObject);
      
      
    //Continue with the login

    loginData.accountId = data.username;
    loginData.ilpBearerToken = data.ilpBearerToken;
    loginData.totpToken = user.totpToken;
    loginData.jwtToken = data.accessToken;
    loginData.profileCompleted = data.profileCompleted;
    loginData.roles = data.roles;


    profileCompletedCondition = data.profileCompleted;
    
    userdata({
      accountId: data.username,
      ilpBearerToken: data.ilpBearerToken,
      totpToken: user.totpToken,
      jwtToken: data.accessToken,
      profileCompleted: data.profileCompleted,
      roles: data.roles
    });

    // activate the webauthn after a successfull login

    const profileName = await determineProfile(data);
    /**If an specific action should be done depending on the profile, use profileName */
    
    // console.log(loadedInfo);
  
    if(data.profileCompleted){
      console.log(navBarEnabled);
      // console.log("if account")

      await updateNavBarEnabled(true);

      history.push("/handle_profile");

      return;
    }

    else{

      const loginParams ={
        username: userNewDevice.username,
        password: userNewDevice.password
      };

      history.push({
        pathname: "/onboarding/personalinformation",
        state: {
          loginParams: loginParams
        }
      });
    }      
      
    } 
    else if(data.status === 401){
      setLoading(false);
      swal({
        title: t("Login.incorrectCredentials"),
        // text: t("Login.incorrectCredentials"),
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    } 
    else if(data.status === 422){
      setLoading(false);
      swal({
        title: t("Login.incorrectCredentials"),
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    } 
    
    else if(data.status === 423){
      setLoading(false);
      swal({
        title: t("Login.accountBlocked"),
        text: t("Login.accountBlockedText"),
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    }
    else{
      setLoading(false);
      swal({
        title: t("Login.requestError"),
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return
    }
  
  };
  
  const[profile, setProfile] = React.useState('');

  const determineProfile = (loginUser) =>{
    return (new Promise((resolve) => {
      if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
        
        if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
          setProfile("ADMIN");
          resolve("ADMIN");
          // history.push({pathname: "/account/handle_account"});
        }

        else{
          setProfile("USER");
          resolve("USER");
        }

      }
    }));
      
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
      <Box
          sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: {xs: "start", sm: "start"},
              width: "95%",
              height: "99%",
              // textAlign: "center",
              marginTop: {xs: "1.3rem", sm: "1rem"},
          }}
      >
          {/* {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :  */}
          {loading ? loaderAnimation :
          <>
            <Grid container sx={{ position: "absolute", top: 0, left: 0}}>
              <Grid item xs={12}>
                <Box 
                    component="img"
                    sx={{
                      width: {xs:"80%", sm:"30%", md: "20%"},
                      height: {xs:"auto", sm:"auto", md: "auto"},
                    }}
                    alt="Logo"
                    src="/images/logo-mpk.png"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{fontWeight: "bold"}}>{t("Register.slogan")}</Typography>
              </Grid>
            </Grid>
            <Grid container sx={{position: "absolute", bottom: 30}}>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Typography variant="body2" sx={{color: Colors.primary, fontWeight: "bold"}}>{t("Register.footerText")}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} sx={{display: "flex", justifyContent: "center", marginTop:{xs: "22%", sm: "0rem"}}}>
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", textAlign: "center", marginTop: "6%"}}>
                <Typography variant="h4">{steps[stepNumber].name}</Typography>
              </Grid>
              {steps[stepNumber].key === "username" && autocompleteUsers ? (
                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "5rem"}}>
                  <Autocomplete sx={{width: {xs: "90%", sm: "90%", md: "30%"}}}
                    {...usersAssignedToDevice}
                    fullWidth
                    clearOnEscape
                    autoComplete = {true}
                    noOptionsText = {t("Login.userNotDevice")}
                    onChange={onChangeAutocomplete}
                    onInputChange={onChange}
                    renderInput={(params) => (
                      <TextField {...params} label={t("Login.email")} variant="standard" />
                    )}
                  />
                </Grid>
              ) : steps[stepNumber].key === "password" ? (
                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "5rem"}}>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    sx={{width: {xs: "90%", sm: "90%", md: "30%"}}}
                    fullWidth
                    variant="standard"
                    value={inputText}
                    onChange={onChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handlePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )
              :
                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "5rem"}}>
                  <TextField
                    fullWidth
                    sx={{width: {xs: "90%", sm: "90%", md: "30%"}}}
                    variant="standard"
                    value={inputText}
                    onChange={onChange}
                  />
                </Grid> 
              }
              {steps[stepNumber].key === "username" ?

                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Button onClick={getUserCredentials} variant="contained" size="big" sx={{backgroundColor: Colors.primary, marginTop: "4rem", height: "3rem", width: {xs: "50%", sm: "30%", md: "20%"}, borderRadius: "100px 100px 100px 100px",}}>
                        OK
                    </Button>
                </Grid>
              :
                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Button onClick={handleNextClick} variant="contained" size="big" sx={{backgroundColor: Colors.primary, marginTop: "4rem", height: "3rem", width: {xs: "50%", sm: "30%", md: "20%"}, borderRadius: "100px 100px 100px 100px",}}>
                        OK
                    </Button>
                </Grid>
              }
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "2rem"}}>
                <Link to="/forgot-password">{t("Login.passwordForgotten")}</Link>
              </Grid>
            </Grid>
          </>
          }
      </Box>
          
    </Box>
  );
}