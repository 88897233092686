import React from 'react';
import {fetchSingleClientsInformation} from "../../utilityFunctions/FetchUtil.js";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors"; 
import Typography from '@mui/material/Typography';
import { AiOutlineIdcard } from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import { loaderAnimation } from '../../Api.js';
import { useTranslation } from 'react-i18next';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;


const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
}

let role;


export default function ClientRol(props) {

    const {loginUser} = props

    const { t } = useTranslation();

    const[loading, setLoading] = React.useState(false);
    const[profile, setProfile] = React.useState('');

    React.useEffect(() => {
        setLoading(true);
        handleRole();
        getPii();

    }, []);

    const getPii = async () => {

        const clientInfo = await fetchSingleClientsInformation(loginUser.accountId, loginUser.jwtToken);

        if(!clientInfo){
            return;
        }

            setProfile(
                <Grid container direction="row" spacing={2} sx={{justifyContent: "center", marginTop: {xs: "2%", sm: "2%", md: "3%"}}}>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: {xs: "center", sm: "start"}, alignItems: "center"}}>
                        <Typography  sx={{typography:{xs: "h4", sm: "h4"}, color: Colors.primary, fontWeight: "bolder", marginLeft: {xs: 0, sm: 0, md: "5%"}}}>{t("ClientRol.myProfile")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} sx={{display: "flex", justifyContent: {xs: "center", sm: "start"}, alignItems: "center"}}> 
                        <Grid container direction="column" spacing={4}>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", width: "100%", justifyContent: {xs: "center", sm: "center"}}}>
                                <Avatar alt="Perfil"  style={{ height: '130px', width: '130px' }}>
                                    <PersonIcon style={{fontSize: "3rem"}} />
                                </Avatar>
                            </Grid>
                        </Grid>                       
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} sx={{textAlign: {xs: "center", sm: "start"}}}> 
                        <Typography sx={{typography:{xs: "h5", sm: "h4"}}}>
                            {`${clientInfo.firstName}`}
                        </Typography>
                        <Grid container direction="row" spacing={4} style={{justifyContent: "start", marginTop: "1.1rem"}}> 
                            {/* <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                <AiOutlineIdcard size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                <Typography variant="body1" color="text.secondary">
                                    {`${clientInfo.idNumber}`}
                                </Typography>   
                            </Grid> */}

                            <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                <FaMobileAlt size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                <Typography variant="body1" color="text.secondary">
                                    {`${clientInfo.phone}`}
                                </Typography>   
                            </Grid>

                            <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                <HiOutlineMail size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                <Typography variant="body2" color="text.secondary">
                                    {`${clientInfo.username}`}
                                </Typography>   
                            </Grid>                                
                        </Grid>
                    </Grid>
                </Grid>
            );
            setLoading(false);
        

    };

    const handleRole = () => {
        return(new Promise((resolve) => {
            if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
                role = loginUser.roles[0].authority;
                console.log(role);
                resolve(role);
            }
        }));
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: "1.5rem"},
                }}
            >
                {loading ? loaderAnimation :
                    <>
                    <Grid container sx={{position: "absolute", bottom: 30}}>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography variant="body2" sx={{color: Colors.primary, fontWeight: "bold"}}>{t("Register.footerText")}</Typography>
                        </Grid>
                    </Grid>
                    {profile}
                    </>
                }
                </Box>
        </Box>
    )   
}
